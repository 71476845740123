.mdc-floating-label {
  color: white !important;
}

.mdc-text-field__input {
  color: white !important;
}

.login {
  color: white;
  background: transparent linear-gradient(165deg, #0057ff 0%, #4063d6 100%) 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100vh;

  --ino-button-color-primary: white;
  --ino-button-color-primary-contrast: black;
  --ino-button-color-primary-light: rgba(255, 255, 255, 0.5);
  --ino-button-color-primary-dark: rgba(255, 255, 255, 0.7);
  --ino-input-icon-color: white;
  --ino-input-caret-color: white;
  --ino-input-label-color: white;
  --ino-input-line-color: white;

  .form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 33%;

    h1 {
      margin: 0;
    }

    p {
      margin: 27px 0 0 0;
      font: normal normal 300 24px/35px Poppins;
      letter-spacing: 0.24px;
      text-align: left;
    }

    .input {
      margin-top: 52px;

      .icon {
        cursor: pointer;
      }
    }

    .error {
      margin-top: 5px;
      color: #eb003b;
    }

    .submit {
      margin-top: 52px;
    }
  }

  .logo {
    margin-bottom: 20px;
    width: 33px;
    height: 33px;
  }

  .illustration {
    position: relative;
    top: -257px;
    height: 850px;
  }
}
