$connected-color: #1fb141;
$disconnected-color: #e74c3c;

.status {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .connection-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 28px;
    width: 20%;

    .icon {
      visibility: hidden;
      align-self: center;
      width: 18px;
      height: 18px;
      margin-bottom: 10px;
      animation: reveal 0s 2s forwards;

      &.delay {
        animation-delay: 4s;
      }
    }

    .connection-line {
      width: 0;
      height: 2px;
      border-top: 2px dashed #0057ff;
      animation: connect 2s forwards;

      &-cloud {
        width: 0;
        height: 2px;
        border-top: 2px dashed #0057ff;
        animation: connect 2s 2s forwards, connected 0s 4s forwards;
      }

      &.connected {
        border-color: $connected-color;
      }

      &.disconnected {
        border-color: $disconnected-color;
      }
    }
  }
}

@keyframes connect {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes connected {
  to {
    border-color: $connected-color;
  }
}

@keyframes reveal {
  to {
    visibility: visible;
  }
}
