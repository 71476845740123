.icon-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #efd6f6;
  border-radius: 50%;
  padding: 6px;

  &.text {
    width: auto;
    height: auto;
    border-radius: 50px;
    padding: 12px 9px;
  }

  &:hover {
    cursor: pointer;
    background-color: #e8c2f3;
  }

  .text {
    color: #ad2dd6;
    font-size: 12px;
    font-weight: 600;
    margin: 0px 10px;
  }
}
