.card {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  box-shadow: 0px 0px 32px #455c6e29;
  padding: 15px 25px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-size: cover;
  background-color: white;

  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #0057ff;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .content {
    height: 100%;
  }
}
