.status-container {
  display: flex;
  flex-direction: column;
  color: #8b8b8b;
  font-weight: 600;
  margin-bottom: 10px;

  .status-text {
    color: #4c95fb;
    font-size: 18px;
  }
}

.challenge-container {
  color: #8b8b8b;
  margin-top: 20px;
  font-size: 16px;
}
