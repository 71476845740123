.temperature-container {
  display: flex;
  align-items: center;

  .status-container {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    color: #8b8b8b;
    font-weight: 600;

    .status-text {
      color: black;
      font-size: 59px;
      line-height: 59px;
      font-weight: bold;
    }
  }
}

.challenge-container {
  color: #8b8b8b;
  margin-top: 20px;
  font-size: 16px;
}
