$color: #0057ff;

.dashboard {
  padding: 30px 50px;
  background-color: #f9fafb;
  height: 100vh;
  background-image: url("../../assets/illu-bg-dashboard.svg");
  background-repeat: no-repeat;
  border-left: 5px solid $color;
  border-right: 5px solid $color;
  border-bottom: 5px solid $color;

  ‚ .icon {
    width: 66px;
    height: 66px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .logo-wrapper {
    display: flex;
    align-items: center;
    .logo {
      fill: white;
      width: 33px;
      height: 33px;
    }

    .title {
      color: white;
      font-weight: 600;
      font-size: 26px;
      margin-left: 10px;
    }
  }
}

.tiles {
  display: flex;
  gap: 20px;

  .left {
    display: flex;
    flex-direction: column;
    flex-basis: 66%;
    gap: 20px;

    .bottom {
      display: flex;
      gap: 20px;
    }
  }

  .right {
    display: flex;
    flex-basis: 33%;
  }
}

#key-facts {
  p {
    margin: 5px;
  }
}
