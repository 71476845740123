.content {
  height: 100%;
  position: relative;
}

.cube {
  height: 100%;
  width: 100%;
  flex-grow: 1;
}

.centered-text {
  display: grid;
  place-items: center;
  height: 100%;
  width: 100%;
}

.button-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: .3s ease-in-out;

  &.revealed {
    opacity: 1;
    transform: scaleY(1);
  }
}
